export default [
  {
    title: {
      ru: 'ActiviTip',
      en: 'ActiviTip',
    },
    url: 'https://polyakovin.github.io/activitip-prod/',
    description: {
      ru: 'Сервис для подбора идеи провести время',
      en: 'Service for searching ideas to spend time',
    },
    icon: 'activitip',
  },
  {
    title: {
      ru: 'TTS Reader',
      en: 'TTS Reader',
    },
    url: 'https://polyakovin.github.io/tts-reader-prod/',
    description: {
      ru: 'Читалка текстовых книг вслух',
      en: 'Text books aloud-reader',
    },
    icon: 'tts-reader',
  },
  {
    title: {
      ru: 'Тренажёр РЖЯ',
      en: 'Sign Language Trainer',
    },
    url: 'https://polyakovin.github.io/learn-sign-lang/',
    description: {
      ru: 'Как «Stamina», только учим жестовый язык',
      en: '«Stamina» for sign language',
    },
    icon: 'learn-sign-lang',
  },
  {
    title: {
      ru: 'Мой день',
      en: 'My Day',
    },
    url: 'https://polyakovin.github.io/daily-organizer/',
    description: {
      ru: 'Менеджер задач на текущий день',
      en: 'Task manager for the current day',
    },
    icon: 'my-day',
  },
  // {
  //   title: {
  //     ru: 'Memit',
  //     en: 'Memit',
  //   },
  //   url: 'https://polyakovin.github.io/memit-prod/',
  //   description: {
  //     ru: 'Менеджер повторяющихся задач',
  //     en: 'Manager of recurring tasks',
  //   },
  //   icon: 'memit',
  // },
  // {
  //   title: {
  //     ru: 'OnLearn',
  //     en: 'OnLearn',
  //   },
  //   url: 'https://polyakovin-on-learn.herokuapp.com/',
  //   description: {
  //     ru: 'Сборник обучающих материалов',
  //     en: 'Collection of training materials',
  //   },
  //   icon: 'on-learn',
  // },
  {
    title: {
      ru: 'Распорядок дня',
      en: 'Daily Routine',
    },
    url: 'https://polyakovin.github.io/franklinTable/',
    description: {
      ru: 'Менеджер по отработке привычек',
      en: 'Habit Development Manager',
    },
    icon: 'franklin-table',
  },
  {
    title: {
      ru: 'Генератор заявок',
      en: 'Applications Generator',
    },
    url: 'https://polyakovin.github.io/bastion-application-prod/',
    description: {
      ru: 'Шаблон заявок на установку дверей',
      en: 'Template of applications for the doors installation',
    },
    icon: 'bastion-application',
  },
];
