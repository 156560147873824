import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { routing } from './app.routing';

import { AppComponent } from './app.component';
import { HttpService } from './http.service';
import { CommonService } from './common.service';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
mergeIcons();

import { PortfolioComponent } from './portfolio/portfolio.component';

import { SiteComponent } from './site/site.component';
import { SkillsComponent } from './site/skills/skills.component';
import { HobbiesComponent } from './site/hobbies/hobbies.component';
import { ContactsComponent } from './site/contacts/contacts.component';
import { PhotoComponent } from './site/photo/photo.component';
import { FlagComponent } from './site/flag/flag.component';
import { BannerComponent } from './site/projects/banner/banner.component';
import { LettersComponent } from './site/letters/letters.component';
import { MentoringComponent } from './site/mentoring/mentoring.component';
import { CompaniesComponent } from './site/companies/companies.component';
import { BlogsComponent } from './site/blogs/blogs.component';
import { ProjectsComponent } from './site/projects/projects.component';
import { MentionsComponent } from './site/mentions/mentions.component';

import { ModalComponent } from './modal/modal.component';

import { BlogComponent } from './blog/blog.component';
import { ArticleComponent } from './blog/article/article.component';
import { AppsComponent } from './site/apps/apps.component';
import { ProjectsService } from './site/projects/projects.service';

@NgModule({
  declarations: [
    AppComponent,
    SiteComponent,
    SkillsComponent,
    HobbiesComponent,
    ContactsComponent,
    PhotoComponent,
    PortfolioComponent,
    FlagComponent,
    BannerComponent,
    ModalComponent,
    LettersComponent,
    MentoringComponent,
    CompaniesComponent,
    BlogsComponent,
    ProjectsComponent,
    BlogComponent,
    ArticleComponent,
    MentionsComponent,
    AppsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    FontAwesomeModule
  ],
  providers: [ HttpService, CommonService, ProjectsService, ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

function mergeIcons() {
  const fa = {...fas, ...fab};
  for (const icon of Object.keys(fa)) {
    fa[icon].prefix = 'fas';
  }
  library.add(fa);
}
