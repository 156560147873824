export default [
  {
    year: 2018,
    name: {
      ru: 'Мобильное приложение Hello TV',
      en: 'Hello TV mobile app',
    },
    description: {
      ru: 'Мобильное приложение для iOS и Android. Реализован личный кабинет учеников, которые могут видеть свои достижения и доступные награды. Зайти в него можно по логину <code>brunya@cat.ru</code> и паролю <code>123456</code>.',
      en: 'Mobile application for iOS and Android. There is a personal account of students who can see their achievements and available awards. You can sign in via login <code>brunya@cat.ru</code> and password <code>123456</code>.',
    },
    img: 'hellotv.jpg',
    links: [
      {
        url: 'https://itunes.apple.com/gb/app/hello-tv/id1438232244',
        name: {
          ru: 'Приложение на iOS',
          en: 'iOS app',
        },
        icon: 'apple',
      },
      {
        url: 'https://play.google.com/store/apps/details?id=io.ionic.polyakovintvhello',
        name: {
          ru: 'Приложение на Android',
          en: 'Android app',
        },
        icon: 'android',
      },
      {
        url: 'http://tvhello.ru/',
        name: {
          ru: 'Сайт заказчика',
          en: 'Customer\'s website',
        },
        icon: 'globe',
      },
    ],
    feedback: true,
    best: true,
    forBanner: true,
  },
  {
    year: 2018,
    name: {
      ru: 'EdLabs',
      en: 'EdLabs',
    },
    description: {
      ru: 'Простой сайт, приглашающий принять участие в новой образовательной платформе. Реализована система регистрация новых пользователей.',
      en: 'A simple website inviting the visiton to participate in a new educational platform. There is a system for registering new users implemented.',
    },
    img: 'edlabs.jpg',
    links: [
      {
        url: 'http://edlabs.ru/',
        name: {
          ru: 'Сайт проекта',
          en: 'Project\'s website',
        },
        icon: 'globe',
      },
    ],
    feedback: true,
    best: true,
    forBanner: true,
  },
  {
    year: 2018,
    name: {
      ru: 'Моё портфолио',
      en: 'My portfolio',
    },
    description: {
      ru: 'Возникла идея создать CV в формате посадочной страницы для лучшей демонстации своих возможностей в качестве веб-разработчика. Добавлены превьюшки и ссылки на основные и второстепенные проекты и средства коммуникации со мной. Ключевые навыки представлены в виде интерактивной инфографики.',
      en: 'There was an idea to create a CV in the format of the landing page for the best demonstration of my opportunities as a web-developer. I\'ve added preview images and links to basic and secondary projects and communication links. Key skills are presented as an interactive infographics.',
    },
    img: 'portfolio.jpg',
    links: [
      {
        url: 'http://polyakovin.ru',
        name: {
          ru: 'Текущий сайт',
          en: 'Current website',
        },
        icon: 'globe'
      },
      {
        url: 'https://github.com/polyakovin/portfolio',
        name: {
          ru: 'Рабочий репозиторий проекта',
          en: 'Project\'s working repository',
        },
        icon: 'github-alt'
      },
      {
        url: 'https://vk.com/ip.painter?w=wall-94792100_14',
        name: {ru: 'Пост о проекте и других CV', en: 'Publication about this project and other CVs'},
        icon: 'vk'
      }
    ],
    best: true,
    forBanner: true
  },
  {
    year: 2018,
    name: {ru: 'Сайт фотографа Алёны Дёминой', en: 'Alena Demina photographer website'},
    description: {ru: 'Сайт фотографа, отображающий лучшие его работы и содержащий тематические ссылки на соцсети с расширенным набором фотографий из портфолио.', en: 'The site of the photographer, displaying her best works and containing thematic links to social networks with an expanded set of photos from the portfolio.'},
    img: 'demina.jpg',
    feedback: true,
    best: true,
    forBanner: true
  },
  {
    year: 2018,
    name: {ru: 'Мобильное приложение Emotion Miner', en: 'Emotion Miner mobile app'},
    description: {ru: 'Техническая демонстрация мобильного приложения для платформы Emotion Miner. Реализованы ключевые элементы управления приложением, заточенные под мобильные устройства. Проект подготовлен для загрузки на iOS, Android и Windows Phone.', en: 'Technical demonstration of the mobile application for the Emotion Miner platform. Implemented key application control elements  designed for mobile devices. The project is prepared for download on iOS, Android and Windows Phone.'},
    img: 'emotionminer_m.jpg',
    links: [
      {
        url: 'https://emotion-miner-ionic-test.herokuapp.com/',
        name: {ru: 'Тестовый сайт проекта', en: 'Project\'s test website'},
        icon: 'cog'
      }
    ],
    feedback: true
  },
  {
    year: 2017,
    name: {ru: 'Emotion Miner', en: 'Emotion Miner'},
    description: {ru: 'Платформа для сбора датасетов эмоций людей на фрагментах видео из YouTube. На платформу может зайти любой человек, пройти небольшой тест и обучение по разметке эмоций, а после этого начать зарабатывать и выводить деньги за разметку. Внедрена поддержка веб-камеры, записывающей эмоциональное состояние человека, размечающего видео. На платформе зарегистрировано и работает более 50.000 человек.', en: 'The platform for collecting datasets of people\'s emotions at video fragments from YouTube. Any person can sign up to the platform, pass a short test and a training on emotions annotating. And after that he can start working and withdraw money for markup. Implemented support for webcam, recording the emotional state of a person marking a video. There are over 50,000 people registered and working at the platform.'},
    img: 'emotionminer.jpg',
    links: [
      {
        url: 'https://emotionminer.com',
        name: {ru: 'Сайт проекта', en: 'Project\'s website'},
        icon: 'globe'
      },
      {
        url: 'http://neurodatalab.com',
        name: {ru: 'Сайт заказчика', en: 'Customer\'s website'},
        icon: 'globe'
      },
      {
        url: 'https://youtu.be/dTLLGIVRFj8',
        name: {ru: 'Обзор на платформу от филиппинского видеоблоггера', en: 'Overview of the platform from the Filipino videoblogger'},
        icon: 'youtube'
      }
    ],
    feedback: true,
    best: true,
    forBanner: true
  },
  {
    year: 2017,
    name: {ru: 'OYWO', en: 'OYWO'},
    description: {ru: 'Оптимальная мотивационная система контроля своего времени и занятости. Разработан список дел и календарь, работающие в связке друг с другом. Есть возможность входить в систему через соцсети.', en: 'Optimal motivational system for controlling personal and working time. There was developed a todo-list working together in conjunction with a calendar. There is an opportunity to sign in to the system through social networks.'},
    img: 'oywo.jpg',
    links: [
      {
        url: 'https://oywo.herokuapp.com/',
        name: {ru: 'Сайт проекта', en: 'Project\'s website'},
        icon: 'globe'
      },
      {
        url: 'https://vk.com/useoywo',
        name: {ru: 'Поддержки проекта в группе ВКонтакте', en: 'Project\'s support VK group'},
        icon: 'vk'
      }
    ],
    feedback: true,
    best: true,
    forBanner: true
  },
  {
    year: 2017,
    name: {ru: 'Мой блог', en: 'My blog'},
    description: {ru: 'Блог-платформу на движке Jekyll, позволяющие писать статьи на языке Markdown. Внедрил возможность вставлять программный код с подсветкой, а также LaTeX-формулы.', en: 'A blog-platform on the Jekyll engine, which allows writing articles in the Markdown language. Implemented the ability to insert highlighted code, as well as LaTeX-equations.'},
    img: 'blog.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io',
        name: {ru: 'Сайт проекта', en: 'Project\'s website'},
        icon: 'globe'
      },
      {
        url: 'https://github.com/polyakovin/polyakovin.github.io',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github-alt'
      }
    ],
    ohNo: true
  },
  {
    year: 2017,
    name: {ru: 'Конструктор документов для заказа', en: 'The document builder for the order'},
    description: {ru: 'Упрощено делопроизводство в компании «Бастион», изготавливающей стальные двери. Программа принимает на вход все необоходимые параметры заказа и выдаёт документ, пригодный для печати.', en: 'Оffice work in the producing steel doors company “Bastion” has been simplified. The program accepts all required parameters of the order for input and produces a document suitable for printing.'},
    img: 'bastion.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io/bastionRequest',
        name: {ru: 'Архивная копия проекта', en: 'Project\'s archive copy'},
        icon: 'archive'
      },
      {
        url: 'https://github.com/polyakovin/bastionRequest',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github-alt'
      }
    ],
    feedback: true,
    best: true
  },
  {
    year: 2016,
    name: {ru: 'Интерактивные уроки для детей', en: 'Interactive lessons for children'},
    description: {ru: 'В рамках участия в образовательном проекте UCHi.RU создал несколько обучающих игр, полезных функций и компонентов общего назначений. Разработан и внедрён в систему разработки сборщик документации по программным компонентам компании.', en: 'While taking part in the educational project UCHi.RU, there were developed several training games, some useful functions and general purpose components. There also was developed and implemented the documentation collector for the software components of the company in the development system.'},
    img: 'uchi.jpg',
    video: 'https://vk.com/video_ext.php?oid=-94792100&id=456239017&hash=3371d6e8fcab148c',
    links: [
      {
        url: 'https://uchi.ru',
        name: {ru: 'Образовательный проект UCHi.RU', en: 'Educational project UCHi.RU'},
        icon: 'globe'
      },
      {
        url: 'https://vk.com/ip.painter?w=wall-94792100_15',
        name: {ru: 'Демонстрация работы функции Drug\'n\'Drop', en: 'Drag&drop function demonstration'},
        icon: 'vk'
      }
    ],
    feedback: true,
    best: true,
    forBanner: true
  },
  {
    year: 2016,
    name: {ru: 'Веб-сайт компании «Ваш Выбор!»', en: '“Your Choice!” company website'},
    description: {ru: 'Ребрендинг веб-сайта фирмы в соответствии с новым фирменным стилем. Количество элементов на сайте сокращено до необходимого минимума. Выделены ключевые достоинства компании и продукции перед конкурентами.', en: 'Rebranding the company\'s website in accordance with the new corporate style guide. The number of elements on the site has been reduced to the required minimum. There are the key advantages of the company and products in compare to competitors highlighted.'},
    img: 'yourChoice2.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io/yourChoice2',
        name: {ru: 'Архивная копия проекта', en: 'Project\'s archive copy'},
        icon: 'archive'
      },
      {
        url: 'https://github.com/polyakovin/yourChoice2',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github'
      }
    ],
    feedback: true,
    best: true,
    forBanner: true
  },
  {
    year: 2015,
    name: {ru: 'Мой первый личный сайт', en: 'My first personal site'},
    description: {ru: 'Представлена фотография автора, ссылки на средства коммуникации, а также перечслены основные проекты.', en: 'There is a picture of the author, links to the means of communication, and the main projects shown.'},
    img: 'portfolio_old.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io/portfolio_old/',
        name: {ru: 'Архивная копия проекта', en: 'Project\'s archive copy'},
        icon: 'archive'
      },
      {
        url: 'https://github.com/polyakovin/portfolio_old',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github-alt'
      }
    ],
    ohNo: true
  },
  {
    year: 2015,
    name: {ru: 'Веб-сайт экзамена по ТФКП', en: 'TFCV exam web-site'},
    description: {ru: 'Автоматизирован процесс отбора достойных из желающих попасть на досрочную сдачу экзамена. Внедрён административный блок для управления содержанием сайта.', en: 'The process of selecting the best students willing to attend an early exam has been automated. Implemented an administrator\'s cabinet for managing the content of the site.'},
    img: 'tfcv.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io/tfkp',
        name: {ru: 'Архивная копия проекта', en: 'Project\'s archive copy'},
        icon: 'archive'
      },
      {
        url: 'https://github.com/polyakovin/tfkp',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github-alt'
      }
    ],
    feedback: true
  },
  {
    year: 2013,
    name: {ru: 'Электронная комната проекта «SMIDA»', en: '“SMIDA” project e-room'},
    description: {ru: 'Сервис для совместной работы участников научного проекта Университетского Центра Свальбарда. В рамках проекта реализована система пользователей, файлообменник, библиотека, поддержка двух языков, система полезных ссылок по проекту.', en: 'Service for the joint work of the participants of the University Center of Svalbard scientific project. There are a users system, file sharing, library, support for two languages, a system of useful links for the project have been implemented.'},
    img: 'smida.jpg',
    links: [
      {
        url: 'http://smida.mipt.ru',
        name: {ru: 'Сайт проекта', en: 'Project\'s website'},
        icon: 'globe'
      },
      {
        url: 'https://github.com/polyakovin/smida',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github-alt'
      }
    ],
    feedback: true,
    best: true,
    forBanner: true
  },
  {
    year: 2012,
    name: {ru: 'Веб-сайт компании «Классика тренинга»', en: '“Training Classics” company website'},
    description: {ru: 'Сайт-визитка с администивным блоком, позволяющим изменять основную информацию на сайте, а также добавлять новости и даты мероприятий фирмы.', en: 'A simple website with an administrator\'s cabinet that allows to change the basic information on the site, as well as adding news and dates for company events.'},
    img: 'trainingСlassics.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io/trainingClassics',
        name: {ru: 'Архивная копия проекта', en: 'Project\'s archive copy'},
        icon: 'archive'
      },
      {
        url: 'https://github.com/polyakovin/trainingClassics',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github'
      }
    ]
  },
  {
    year: 2011,
    name: {ru: 'Веб-сайт компании «Ваш Выбор!»', en: '“Your Choice!” company web-site'},
    description: {ru: 'Сайта предназначался главным образом в качестве платформы для размещения конструктора металлической двери. Помимо основного продукта на сайте реализован каталог дверей в виде буклета, форма заказа двери, контактная информация, а также администраторский блок для наполнения сайта содержимым.', en: 'The site was intended primarily as a platform for placing the metal door builder. In addition to the main product there is a catalog of doors in the form of a booklet, a door order form, contact information, and an administrator\'s block for filling the site with content on the site.'},
    img: 'yourChoice.jpg',
    links: [
      {
        url: 'https://polyakovin.github.io/yourChoice',
        name: {ru: 'Архивная копия проекта', en: 'Project\'s archive copy'},
        icon: 'archive'
      },
      {
        url: 'https://polyakovin.github.io/yourChoice/constructor.html',
        name: {ru: 'Конструктор стальных дверей', en: 'Steel door builder'},
        icon: 'archive'
      },
      {
        url: 'https://github.com/polyakovin/yourChoice',
        name: {ru: 'Рабочий репозиторий проекта', en: 'Project\'s working repository'},
        icon: 'github-alt'
      }
    ],
    feedback: true
  }
]
