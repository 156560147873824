/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mentoring.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mentoring.component";
import * as i4 from "../../common.service";
var styles_MentoringComponent = [i0.styles];
var RenderType_MentoringComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MentoringComponent, data: {} });
export { RenderType_MentoringComponent as RenderType_MentoringComponent };
function View_MentoringComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [["target", "_blank"]], [[8, "className", 0], [8, "title", 0], [8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ("bubble b-" + _v.context.$implicit.photo); var currVal_1 = _v.context.$implicit.name; var currVal_2 = _v.context.$implicit.url; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MentoringComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["id", "mentoring"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "frames"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "iframe", [["allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"], ["allowfullscreen", ""], ["frameborder", "0"], ["height", "400"], ["src", "https://www.youtube.com/embed/videoseries?list=PL2Hphj-qfWb4hlhsWuLsIT2HlwbH3LBYN"], ["width", "700"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "feedback-bubble"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MentoringComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.students; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title[_co.common.lang]; _ck(_v, 1, 0, currVal_0); }); }
export function View_MentoringComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mentoring", [], null, null, null, View_MentoringComponent_0, RenderType_MentoringComponent)), i1.ɵdid(1, 114688, null, 0, i3.MentoringComponent, [i4.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MentoringComponentNgFactory = i1.ɵccf("app-mentoring", i3.MentoringComponent, View_MentoringComponent_Host_0, {}, {}, []);
export { MentoringComponentNgFactory as MentoringComponentNgFactory };
