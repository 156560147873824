/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apps.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./apps.component";
import * as i4 from "../../common.service";
var styles_AppsComponent = [i0.styles];
var RenderType_AppsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppsComponent, data: {} });
export { RenderType_AppsComponent as RenderType_AppsComponent };
function View_AppsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "a", [["rel", "noopener noreferrer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.url; _ck(_v, 1, 0, currVal_0); var currVal_1 = (("../../../assets/images/apps/" + _v.context.$implicit.icon) + ".svg"); var currVal_2 = _v.context.$implicit.title[_co.common.lang]; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.title[_co.common.lang]; _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.context.$implicit.description[_co.common.lang]; _ck(_v, 8, 0, currVal_4); }); }
export function View_AppsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["id", "mentions"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppsComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.apps; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title[_co.common.lang]; _ck(_v, 1, 0, currVal_0); }); }
export function View_AppsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-apps", [], null, null, null, View_AppsComponent_0, RenderType_AppsComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppsComponent, [i4.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppsComponentNgFactory = i1.ɵccf("app-apps", i3.AppsComponent, View_AppsComponent_Host_0, {}, {}, []);
export { AppsComponentNgFactory as AppsComponentNgFactory };
