/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./companies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./companies.component";
import * as i4 from "../../common.service";
var styles_CompaniesComponent = [i0.styles];
var RenderType_CompaniesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompaniesComponent, data: {} });
export { RenderType_CompaniesComponent as RenderType_CompaniesComponent };
function View_CompaniesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4], [8, "className", 0], [8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-position": 0 })], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, (((("-" + _v.context.$implicit.x) + "px -") + _v.context.$implicit.y) + "px")); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; var currVal_1 = ("sprite sprite-" + _v.context.$implicit.img); var currVal_2 = _v.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CompaniesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["id", "companies"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "companies"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompaniesComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.companies; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title[_co.common.lang]; _ck(_v, 1, 0, currVal_0); }); }
export function View_CompaniesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-companies", [], null, null, null, View_CompaniesComponent_0, RenderType_CompaniesComponent)), i1.ɵdid(1, 114688, null, 0, i3.CompaniesComponent, [i4.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompaniesComponentNgFactory = i1.ɵccf("app-companies", i3.CompaniesComponent, View_CompaniesComponent_Host_0, {}, {}, []);
export { CompaniesComponentNgFactory as CompaniesComponentNgFactory };
