import { OnInit } from '@angular/core';
import { CommonService } from '../../common.service';
var FlagComponent = /** @class */ (function () {
    function FlagComponent(common) {
        this.common = common;
    }
    Object.defineProperty(FlagComponent.prototype, "otherLanguage", {
        get: function () {
            return this.common.lang === 'ru' ? 'en' : 'ru';
        },
        enumerable: true,
        configurable: true
    });
    FlagComponent.prototype.ngOnInit = function () { };
    FlagComponent.prototype.toggleLanguage = function () {
        this.common.lang = this.otherLanguage;
    };
    return FlagComponent;
}());
export { FlagComponent };
