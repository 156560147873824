/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./article/article.component.ngfactory";
import * as i7 from "./article/article.component";
import * as i8 from "../http.service";
import * as i9 from "../site/flag/flag.component.ngfactory";
import * as i10 from "../site/flag/flag.component";
import * as i11 from "../common.service";
import * as i12 from "../site/blogs/blogs.component.ngfactory";
import * as i13 from "../site/blogs/blogs.component";
import * as i14 from "../modal/modal.component.ngfactory";
import * as i15 from "../modal/modal.component";
import * as i16 from "./blog.component";
import * as i17 from "@angular/router";
var styles_BlogComponent = [i0.styles];
var RenderType_BlogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogComponent, data: {} });
export { RenderType_BlogComponent as RenderType_BlogComponent };
function View_BlogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openArticle($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_BlogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_BlogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "article-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ")"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.date; _ck(_v, 1, 0, currVal_0); }); }
function View_BlogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "publication-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "fa-layers", [["class", "fa-fw"]], [[2, "fa-fw", null]], null, null, i2.View_FaLayersComponent_0, i2.RenderType_FaLayersComponent)), i1.ɵdid(2, 638976, null, 0, i3.FaLayersComponent, [i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = _ck(_v, 5, 0, "fas", (_co.isKnownPlatform(_v.context.$implicit.platform) ? _v.context.$implicit.platform : "globe")); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).fixedWidth; _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_2); }); }
function View_BlogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_5)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_6)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.href; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.date; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.publications; _ck(_v, 8, 0, currVal_3); }, null); }
function View_BlogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "article-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_2)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.articles; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.category; _ck(_v, 2, 0, currVal_0); }); }
function View_BlogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-article", [], null, null, null, i6.View_ArticleComponent_0, i6.RenderType_ArticleComponent)), i1.ɵdid(1, 638976, null, 0, i7.ArticleComponent, [i8.HttpService], { article: [0, "article"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BlogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-flag", [], null, null, null, i9.View_FlagComponent_0, i9.RenderType_FlagComponent)), i1.ɵdid(2, 114688, null, 0, i10.FlagComponent, [i11.CommonService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "fa-icon", [["class", "close ng-fa-icon"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToSite() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Blog"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_1)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-blogs", [], null, null, null, i12.View_BlogsComponent_0, i12.RenderType_BlogsComponent)), i1.ɵdid(11, 114688, null, 0, i13.BlogsComponent, [i11.CommonService], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "app-modal", [], null, [[null, "modalClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modalClosed" === en)) {
        var pd_0 = ((_co.isModalShown = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ModalComponent_0, i14.RenderType_ModalComponent)), i1.ɵdid(13, 114688, null, 0, i15.ModalComponent, [i11.CommonService], { isShown: [0, "isShown"] }, { modalClosed: "modalClosed" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BlogComponent_7)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _ck(_v, 5, 0, "fas", "chevron-left"); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.categories; _ck(_v, 9, 0, currVal_2); _ck(_v, 11, 0); var currVal_3 = _co.isModalShown; _ck(_v, 13, 0, currVal_3); var currVal_4 = (_co.article !== ""); _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_0); }); }
export function View_BlogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blog", [], null, null, null, View_BlogComponent_0, RenderType_BlogComponent)), i1.ɵdid(1, 114688, null, 0, i16.BlogComponent, [i17.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlogComponentNgFactory = i1.ɵccf("app-blog", i16.BlogComponent, View_BlogComponent_Host_0, {}, {}, []);
export { BlogComponentNgFactory as BlogComponentNgFactory };
