/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./skills.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./skills.component";
import * as i4 from "../../common.service";
var styles_SkillsComponent = [i0.styles];
var RenderType_SkillsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SkillsComponent, data: {} });
export { RenderType_SkillsComponent as RenderType_SkillsComponent };
export function View_SkillsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mindmapElement: 0 }), i1.ɵqud(402653184, 2, { labelElement: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["id", "skills"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["mindmapElement", 1]], null, 5, "div", [["class", "mindmap"]], null, [[null, "mousemove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousemove" === en)) {
        var pd_0 = (_co.changeLabelPosition($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["labelElement", 1]], null, 4, "div", [["class", "label"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { shown: 0 }), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { top: 0, left: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "label"; var currVal_2 = _ck(_v, 7, 0, _co.isLabelShown); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 9, 0, (_co.labelPostion.top + "px"), (_co.labelPostion.left + "px")); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title[_co.common.lang]; _ck(_v, 3, 0, currVal_0); }); }
export function View_SkillsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skills", [], null, null, null, View_SkillsComponent_0, RenderType_SkillsComponent)), i1.ɵdid(1, 114688, null, 0, i3.SkillsComponent, [i4.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SkillsComponentNgFactory = i1.ɵccf("app-skills", i3.SkillsComponent, View_SkillsComponent_Host_0, {}, {}, []);
export { SkillsComponentNgFactory as SkillsComponentNgFactory };
