/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./article.component";
import * as i7 from "../../http.service";
var styles_ArticleComponent = [i0.styles];
var RenderType_ArticleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArticleComponent, data: {} });
export { RenderType_ArticleComponent as RenderType_ArticleComponent };
function View_ArticleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "fa-layers", [["class", "fa-spin"]], [[2, "fa-fw", null]], null, null, i2.View_FaLayersComponent_0, i2.RenderType_FaLayersComponent)), i1.ɵdid(2, 638976, null, 0, i3.FaLayersComponent, [i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _ck(_v, 5, 0, "fas", _co.currentSpinner); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).fixedWidth; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_1); }); }
function View_ArticleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "article", [["id", "wrapper"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.articleHTML; _ck(_v, 0, 0, currVal_0); }); }
export function View_ArticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.articleHTML === ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.articleHTML !== ""); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-article", [], null, null, null, View_ArticleComponent_0, RenderType_ArticleComponent)), i1.ɵdid(1, 638976, null, 0, i6.ArticleComponent, [i7.HttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleComponentNgFactory = i1.ɵccf("app-article", i6.ArticleComponent, View_ArticleComponent_Host_0, { article: "article" }, {}, []);
export { ArticleComponentNgFactory as ArticleComponentNgFactory };
