<h2 id="mentoring">{{ title[common.lang] }}</h2>
<div class="frames">
  <div class="left">
    <iframe
      width="700"
      height="400"
      src="https://www.youtube.com/embed/videoseries?list=PL2Hphj-qfWb4hlhsWuLsIT2HlwbH3LBYN"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <div class="right">
    <div class="feedback-bubble">
      <a
        *ngFor="let student of students"
        [class]="'bubble b-' + student.photo"
        [title]="student.name"
        [href]="student.url"
        target="_blank"
      ></a>
    </div>
    <!-- <iframe
      width="300"
      height="550"
      src="https://www.youtube.com/embed/videoseries?list=PLL_1zCAOOe6ZJp7o-YBVcG5B2molEyQaA"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <iframe
      width="300"
      height="100"
      src="https://profi.ru/backoffice/widget.php?type=300x100&id=ea79e181e4d791d64170227d5c3d08a0"
      frameborder="0"
    ></iframe> -->
  </div>
</div>
