<div class="container cv">
  <app-photo></app-photo>
  <app-letters></app-letters>
  <app-mentoring></app-mentoring>
  <app-companies></app-companies>
</div>

<div class="container">
  <app-apps></app-apps>
</div>

<div class="container-fluid hidden-xs">
  <app-skills></app-skills>
</div>

<app-contacts></app-contacts>
