/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../flag/flag.component.ngfactory";
import * as i3 from "../flag/flag.component";
import * as i4 from "../../common.service";
import * as i5 from "@angular/common";
import * as i6 from "./photo.component";
var styles_PhotoComponent = [i0.styles];
var RenderType_PhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoComponent, data: {} });
export { RenderType_PhotoComponent as RenderType_PhotoComponent };
export function View_PhotoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flag", [], null, null, null, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(1, 114688, null, 0, i3.FlagComponent, [i4.CommonService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "bio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "photo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "figure", [["class", "polaroid figure"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "presentation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(12, { ru: 0 })], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = _ck(_v, 12, 0, (_co.common.lang === "ru")); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title[_co.common.lang]; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.subtitle[_co.common.lang]; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.usp[_co.common.lang]; _ck(_v, 10, 0, currVal_2); }); }
export function View_PhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo", [], null, null, null, View_PhotoComponent_0, RenderType_PhotoComponent)), i1.ɵdid(1, 114688, null, 0, i6.PhotoComponent, [i4.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoComponentNgFactory = i1.ɵccf("app-photo", i6.PhotoComponent, View_PhotoComponent_Host_0, {}, {}, []);
export { PhotoComponentNgFactory as PhotoComponentNgFactory };
