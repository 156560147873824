// https://fontawesome.com/v5/search
export default [
  {
    href: 'mailto:web@polyakovin.ru',
    icon: 'envelope',
    isSelf: true,
  },
  {
    href: 'https://t.me/polyakovin',
    icon: 'telegram-plane',
  },
  {
    href: 'https://www.linkedin.com/in/polyakovin/',
    icon: 'linkedin',
  },
  {
    href: 'https://github.com/polyakovin',
    icon: 'github',
  },
  {
    href: 'https://www.youtube.com/@polyakovin',
    icon: 'youtube',
  },
  {
    href: 'https://www.tiktok.com/@polyakovin',
    icon: 'tiktok',
  },
  {
    href: 'https://instagram.com/polyakovin',
    icon: 'instagram',
  },
  {
    href: 'https://vk.com/polyakovin',
    icon: 'vk',
  },
];
