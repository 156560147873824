<app-flag></app-flag>
<div class="bio">
  <div class="photo">
    <figure class="polaroid figure"></figure>
  </div>
  <div class="presentation">
    <h1>{{ title[common.lang] }}</h1>
    <div class="subtitle">{{ subtitle[common.lang] }}</div>
    <div [ngClass]="{ ru: common.lang === 'ru' }" [innerHTML]="usp[common.lang]"></div>
  </div>
</div>
