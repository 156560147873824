/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./contacts.component";
import * as i7 from "../../common.service";
var styles_ContactsComponent = [i0.styles];
var RenderType_ContactsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsComponent, data: {} });
export { RenderType_ContactsComponent as RenderType_ContactsComponent };
function View_ContactsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "contacts"]], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(3, 2)], function (_ck, _v) { var currVal_3 = _ck(_v, 3, 0, "fas", _v.context.$implicit.icon); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.href, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.isSelf ? "_self" : "_blank"), ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_2); }); }
export function View_ContactsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "footer", [["id", "contacts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contacts; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ContactsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts", [], null, null, null, View_ContactsComponent_0, RenderType_ContactsComponent)), i1.ɵdid(1, 114688, null, 0, i6.ContactsComponent, [i7.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsComponentNgFactory = i1.ɵccf("app-contacts", i6.ContactsComponent, View_ContactsComponent_Host_0, {}, {}, []);
export { ContactsComponentNgFactory as ContactsComponentNgFactory };
