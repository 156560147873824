<h2 id="companies">{{ title[common.lang] }}</h2>
<div class="companies">
  <a
    *ngFor="let company of companies"
    [href]="company.href"
    [class]="'sprite sprite-' + company.img"
    [ngStyle]="{
      'background-position': '-' + company.x + 'px -' + company.y + 'px'
    }"
    [title]="company.title"
    target="_blank"
  ></a>
</div>
