<div class="modal fade" [ngClass]="{show: isShown}">
  <div class="modal-dialog">
    <div class="modal-content">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="modal-backdrop fade"
    [ngClass]="{show: isShown}"
    (click)="closeModal()">
  </div>
</div>
