<h2 id="mentions">{{ title[common.lang] }}</h2>
<ul>
  <li *ngFor="let app of apps">
    <a [href]="app.url" target="_blank" rel="noopener noreferrer">
      <div class="icon">
        <img
          [src]="'../../../assets/images/apps/' + app.icon + '.svg'"
          [alt]="app.title[common.lang]"
        />
      </div>
      <div>
        <strong>{{ app.title[common.lang] }}</strong>
        <span class="description">{{ app.description[common.lang] }}</span>
      </div>
    </a>
  </li>
</ul>
