<div class="projects-list">
  <app-flag></app-flag>

  <h1>{{ title[common.lang] }}</h1>

  <fa-icon class="close" [icon]="['fas', 'chevron-left']" (click)="backToSite()"></fa-icon>

  <div class="projects-grid">
    <div
      #projectElement
      class="project"
      *ngFor="let project of projectsService.projects"
      (click)="openModal(project)"
      data-toggle="modal"
      data-target="#projects-modal"
    >
      <img
        [src]="'assets/images/projects/landings/' + project.img"
        [alt]="project.name[common.lang]"
      />

      <div class="description">
        <div class="bg"></div>

        <div class="title">
          {{ project.name[common.lang] }}<br />
          <span class="year small">{{ project.year }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal [isShown]="isModalShown" (modalClosed)="isModalShown = false">
  <div *ngIf="openedProject as project">
    <div class="modal-header">
      <h3>
        {{ project.name[common.lang] }}<small>, {{ project.year }}</small>
      </h3>
    </div>

    <div class="modal-body">
      <img
        *ngIf="project.video === undefined"
        class="img-fluid"
        [src]="'assets/images/projects/main-features/' + project.img"
        [alt]="project.name[common.lang]"
      />

      <div *ngIf="project.video !== undefined" class="embed-responsive embed-responsive-16by9">
        <embed class="embed-responsive-item" [src]="project.videoUrl" />
      </div>
    </div>

    <div class="modal-footer">
      <ul class="list-unstyled">
        <li *ngFor="let link of project.links">
          <a [href]="link.url" target="_blank">
            <fa-layers class="fa-fw">
              <fa-icon [icon]="['fas', link.icon || 'link']"></fa-icon>
            </fa-layers>
            {{ link.name[common.lang] }}
          </a>
        </li>
      </ul>

      <p *ngIf="project.description" [innerHTML]="project.description[common.lang] || ''"></p>

      <div class="feedback" *ngIf="project.feedback">
        <h4>{{ feedbackTitle[common.lang] }}</h4>
        <img [src]="'assets/images/projects/feedback/' + project.img" [alt]="project.img" />
      </div>
    </div>
  </div>
</app-modal>
