import { OnInit } from '@angular/core';
import { select, layout } from 'd3';
import { CommonService } from '../../common.service';
import skillsData from './skills';
var SkillsComponent = /** @class */ (function () {
    function SkillsComponent(common) {
        this.common = common;
        this.title = {
            ru: 'Любимые инструменты',
            en: 'Favourite tools',
        };
        this.isLabelShown = false;
        this.labelPostion = {
            top: 0,
            left: 0,
        };
    }
    SkillsComponent.prototype.ngOnInit = function () {
        this.label = this.labelElement.nativeElement;
        this.makeForceDiagram(skillsData);
    };
    SkillsComponent.prototype.makeForceDiagram = function (skills) {
        this.mindmap = select(this.mindmapElement.nativeElement);
        var svg = this.mindmap.append('svg');
        var force = layout.force();
        var _a = this.createNodesAndLinks(skills), nodes = _a[0], links = _a[1];
        this.setupForceDiagram(force, nodes, links);
        this.watchGraphSize(svg, force);
        var link = this.drawLinks(svg, links);
        var node = this.drawNodes(svg, nodes, force);
        this.appendIconsToNodes(node);
        this.addTooltips(node);
        this.animateForceDiagramOnTick(force, link, node);
    };
    SkillsComponent.prototype.createNodesAndLinks = function (skills) {
        var _a = this.parseSkillsToNodesAndLinks(skills), nodes = _a[0], links = _a[1], nodejsIndex = _a[2], backendIndex = _a[3];
        links.push({
            target: nodejsIndex,
            source: backendIndex
        });
        return [nodes, links];
    };
    SkillsComponent.prototype.parseSkillsToNodesAndLinks = function (skills, parent, nodes, links, backendIndex, nodejsIndex) {
        if (parent === void 0) { parent = null; }
        if (nodes === void 0) { nodes = []; }
        if (links === void 0) { links = []; }
        for (var i = 0; i < skills.length; i++) {
            var currentObject = skills[i];
            var currentObjectType = Object.prototype.toString.call(currentObject);
            if (i !== 0 && parent !== null) {
                links.push({
                    target: parent,
                    source: nodes.length
                });
            }
            switch (currentObjectType) {
                case '[object String]':
                    nodes.push({
                        name: currentObject,
                        w: 32,
                        h: 32
                    });
                    break;
                case '[object Object]':
                    if (currentObject.name === 'Node.js') {
                        nodejsIndex = nodes.length;
                    }
                    if (currentObject.name === 'Backend') {
                        backendIndex = nodes.length;
                    }
                    nodes.push(currentObject);
                    break;
                case '[object Array]':
                    var _a = this.parseSkillsToNodesAndLinks(currentObject, nodes.length, nodes, links, backendIndex, nodejsIndex), innerNodes = _a[0], innerLinks = _a[1], innerNodejsIndex = _a[2], innerBackendIndex = _a[3];
                    nodes = innerNodes;
                    links = innerLinks;
                    nodejsIndex = innerNodejsIndex;
                    backendIndex = innerBackendIndex;
                    break;
            }
        }
        return [nodes, links, nodejsIndex, backendIndex];
    };
    SkillsComponent.prototype.setupForceDiagram = function (force, nodes, links) {
        force
            .friction(0.9)
            .distance(80)
            .charge(-250)
            .gravity(0.07)
            .nodes(nodes)
            .links(links);
    };
    SkillsComponent.prototype.watchGraphSize = function (svg, force) {
        var _this = this;
        this.setGraphSize(svg, force);
        select(window).on('resize', function () {
            if (location.pathname === '/') {
                _this.setGraphSize(svg, force);
            }
        });
    };
    SkillsComponent.prototype.setGraphSize = function (svg, force) {
        var mindmapWidth = this.mindmap[0][0].offsetWidth;
        var mindmapHeight = 770;
        svg
            .attr('width', mindmapWidth)
            .attr('height', mindmapHeight);
        force
            .size([mindmapWidth, mindmapHeight])
            .start();
    };
    SkillsComponent.prototype.drawLinks = function (svg, links) {
        return svg.selectAll('.link')
            .data(links)
            .enter().append('line')
            .attr('style', 'stroke: #ccc');
    };
    SkillsComponent.prototype.drawNodes = function (svg, nodes, force) {
        return svg.selectAll('.node')
            .data(nodes)
            .enter().append('g')
            .attr('style', 'cursor: move')
            .call(force.drag);
    };
    SkillsComponent.prototype.appendIconsToNodes = function (node) {
        node.append('image')
            .attr('xlink:href', function (d) { return "assets/images/skills/" + d.name.toLowerCase().replace(/\s/g, '_') + "." + (d.extension || 'svg'); })
            .attr('x', function (d) { return -d.w / 2; })
            .attr('y', function (d) { return -d.h / 2; })
            .attr('width', function (d) { return d.w; })
            .attr('height', function (d) { return d.h; });
    };
    SkillsComponent.prototype.addTooltips = function (node) {
        var _this = this;
        node
            .on('mouseover', function (d) {
            _this.label.innerHTML = d.name;
            _this.isLabelShown = true;
        })
            .on('mouseleave', function () {
            _this.isLabelShown = false;
        });
    };
    SkillsComponent.prototype.animateForceDiagramOnTick = function (force, link, node) {
        force.on('tick', function () {
            link.attr('x1', function (d) { return d.target.x; })
                .attr('y1', function (d) { return d.target.y; })
                .attr('x2', function (d) { return d.source.x; })
                .attr('y2', function (d) { return d.source.y; });
            node.attr('transform', function (d) { return 'translate(' + d.x + ', ' + d.y + ')'; });
        });
    };
    SkillsComponent.prototype.changeLabelPosition = function (_a) {
        var x = _a.offsetX, y = _a.offsetY;
        this.labelPostion.left = x + 20;
        this.labelPostion.top = y - this.label.offsetHeight / 2;
    };
    return SkillsComponent;
}());
export { SkillsComponent };
